<template>
    <b-modal 
        v-model="showModal" 
        id="modal-lg" 
        size="lg" 
        title="Marca"
        :footer-class="'justify-content-between'"
        ref="modal"
        @hide="onHidden"
        >
        <MarcaForm :save.sync="save" :isModal="true" @entity="dataSaved"/>
        <template #modal-footer>
            <button type="button" class="btn btn-outline-dark" @click="hideModal">Cerrar</button>
            <button type="button" class="btn btn-success" @click="submit()" v-loading-btn="{loading: loading}">
                <font-awesome-icon icon="save" /> Guardar marca
            </button>
        </template>
    </b-modal>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import MarcaForm from './MarcaForm.vue';

library.add([ faSave ]);

export default {
	name: 'MarcasModalForm',
    components: {
        MarcaForm
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
	data: function() {
		return {
            showModal: this.show,
            save: false,
            loading: false
		}
	},
    watch: {
        show: function (nuevo) {
            this.showModal = nuevo;
        },
        save: function(nuevo) {
            this.loading = nuevo;
        }
    },
	methods: {
        onHidden() {
            this.$emit('update:show', false);
            this.$emit('closed', true);
        },
        hideModal() {
            this.$refs['modal'].hide()
        },
        submit() {
            let self = this;
            self.save = true;
        },
        dataSaved(entity) {
            this.$emit('entity', entity);
            this.hideModal();
        }
	},
	mounted: function() {
	}
}
</script>