<template>
    <div class="row" v-if="visible">
		<div class="col-12 col-lg-12" v-loading-div="{loading: loading}">
			<table class="table table-striped table-hover table-sm table-bordered">
				<tbody>
				<tr>
					<th>Producto</th>
					<th></th>
				</tr>
				<tr v-for="item in items" :key="item.name">
                    <td class="align-middle">
						<div class="item-col">
							<img v-bind:src="item.images[0]" width="80px" />
							<div class="item-info">
								<span class="item-name">
					    			{{ item.name }}
								</span>
								<span>
									<span class="item-brand">
										{{ item.brand }}
									</span>
									|
									<span class="item-price">
										{{ item.price }}
									</span>
								</span>
							</div>
						</div>
					</td>
                    <td class="align-middle item-actions">
						<a class="btn btn-sm btn-info text-white mr-1" :class="{ disabled: !actionsEnabled }" :href="item.link" target="_blank">
                    		<font-awesome-icon icon="eye" /> 
							<span class="d-md-inline d-none">&nbsp;Ver</span>
						</a>
						<a class="btn btn-sm btn-success text-white" :class="{ disabled: !actionsEnabled }" @click="selectItem(item)">
							<font-awesome-icon icon="check-circle" /> 
							<span class="d-md-inline d-none">&nbsp;Seleccionar</span>
						</a>
					</td>
				</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>
<style>
	div.item-col {
		display: grid;
		grid-template-columns: 100px auto;
	}

	.item-info {
		display: grid;
		grid-template-rows: auto auto;
	}

	.item-brand, .item-price {
		font-weight: bold;
	}
</style>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

library.add([ faEye, faCheckCircle ]);

export default {
	name: 'AddProducto',
	props: {
		buscar: {
			type: String,
			default: null
		},
	},
	watch: {
		buscar: function (value) {
            let self = this;
			if(value) {
				console.log("a buscar! => " + value);
				self.busqueda(value);
			}
        },
	},
	data: () => {
		return {
			visible: false,
			loading: false,
			actionsEnabled: true,
			items: [
			// 	{
			// 		"name": "Arroz grado 1 gran selección grano largo y ancho 1 kg",
			// 		"brand": "Tucapel",
			// 		"code": null,
			// 		"price": "$1.499",
			// 		"link": "https://www.jumbo.cl/arroz-grado-1-tucapel-gran-seleccion-grano-largo-y-ancho-1-kg/p",
			// 		"images": [
			// 			"https://jumbo.vtexassets.com/arquivos/ids/459002-360-360?width=360&height=360&aspect=true"
			// 		]
			// 	},
			// 	{
			// 		"name": "Arroz grado 1 pregraneado grano largo 1 kg",
			// 		"brand": "Tucapel",
			// 		"code": null,
			// 		"price": "$1.349",
			// 		"link": "https://www.jumbo.cl/arroz-grado-1-tucapel-1-kg-pregraneado-grano-largo/p",
			// 		"images": [
			// 			"https://jumbo.vtexassets.com/arquivos/ids/459001-360-360?width=360&height=360&aspect=true"
			// 		]
			// 	}
			]
		}
	},
	methods: {
		selectItem: function(item) {
        	let self = this;
			//verficar y crear la marca
			//luego de crear la marca, retornar el item para que 

			self.actionsEnabled = false;
			self.axios.post(`branches`, {
				name: item.brand
			})
            .then(function(response) {
				item.brand_id = response.data.id;
				console.log('item_seleccionado', item);
				self.$emit('onItemSelect', item);
				self.items = [];
				self.visible = false;
				// self.actionsEnabled = true;
			}).catch(function (error) {
				self.actionsEnabled = true;
                self.$toastr.e(error, "Exception");
            });
		},
		busqueda: function(texto) {
        	let self = this;
			self.visible = true;
			self.loading = true;

			console.log('buscando: => ' + texto);
			self.axiosScapper.post(`jumbo/buscar-listado`, {
				"query": texto
			})
			.then(function(response) {
				self.loading = false;
				// self.$emit('update:save', false);
				if(response.error) {
					// self.loading = false;
					self.$toastr.e(response.message, "Error al buscar en Jumbo");
				} else {
					self.items = [];
					response.map(item => {
						self.items.push(item);
					});
					self.triggerBuscarJumbo = null;
					console.log('response', response)
					// self.loading = false;
					self.$toastr.s("Productos encontrados!", "Correcto");
				}
			})
			.catch(function (error) {
				self.loading = false;
				// self.loading = false;
				// self.$emit('update:save', false);
				self.$toastr.e(error, "Exception");
			});
		}
	},
	mounted: function() {
        let self = this;
		// self.busqueda("prueba!");
	}
}
</script>
