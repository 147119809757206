<template>
    <div class="form-group">
        <label for="marca-id" class="form_label ">Marca</label>
        <v-select 
            class="bg-white"
            v-model="marca"
            :filterable="false"
            :options="items"
            @search="onSearch"
            label="name"
            @input="changeItem"
            :clearable="false"
            :class="{'is-invalid': isInvalid}"
            :disabled="isDisabled"
            >
            <template #selected-option="option">
                <template v-if="option.add || option.name == 'add'">
                    Agregando marca
                </template>
                <template v-else>
                    {{option.name}}
                </template>
            </template>

            <template #option="option">
                <template v-if="option.add || option.name == 'add'">
                    <font-awesome-icon icon="plus" /> Agregar marca
                </template>
                <template v-else>
                    {{option.name}}
                </template>
            </template>

            <template #no-options="{ search }">
                No hay resultados para <b>{{search}}</b>
            </template>
        </v-select>

        <MarcasModalForm v-if="enableCreate" :show.sync="modal" @closed="closeModal" @entity="itemCreated"/>
    </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlus, faEdit, faTrashAlt, faUserCog, faBook} from '@fortawesome/free-solid-svg-icons';
import _ from "lodash";
import MarcasModalForm from './MarcasModalForm.vue';

library.add([faPlus, faEdit, faTrashAlt, faUserCog, faBook]);

export default {
	name: 'MarcasSelect',
    components: {
        MarcasModalForm
    },
    props: {
        isInvalid: {
            type: Boolean,
            default: false
        },
        enableCreate: {
            type: Boolean,
            default: false
        },
        selected: {
            type: String,
            default: null
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        not: {
            type: Array,
            default: () => []
        }
    },
	data: () => {
		return {
            items: [],
            limit: 10,
            page: 0,
            modal: false,
            marca: null
		}
	},
    watch: {
        marca: function (building) {
            // console.log('cambio building', building);
            let buildingId = null
            if(building && building.id != 'add') {
                buildingId = building.id;
            }
            this.$emit('onSelected', buildingId);
        },
        not: function(notBuildingIds) {
            console.log('notBuildingIds', notBuildingIds);
            this.search(null, null, this);
        },
        selected: {
            handler: function(selected) {
                console.log('marca-select selected', selected);
                if (selected) {
                    this.buscarSelected(selected);
                } else {
                    this.onSearch();
                }
            },
            immediate: false,
            deep: true
        }
    },
	methods: {
        onSearch(search, loading) {
            this.search(loading, search, this);
        },
        changeItem(marca) {
            if (marca.id == 'add') {
                this.modal = true;
            }
        },
        closeModal() {
            this.modal = false;
            this.marca = null;
        },
        itemCreated(entity) {
            let self = this;
            self.items = [entity];
            setTimeout(() => {
                self.marca = entity;
            }, 50);
        },
        buscarSelected(selected) {
            let self = this;

            self.axios.get(`branches/${selected}`)
            .then((dataEdificio) => {
                self.items = [dataEdificio.data];
                self.marca = dataEdificio.data;
            }).catch((err) => {
                self.$toastr.e(err, "Exception");
            });
        },
        search: _.debounce((loading, search, self) => {
            if(loading)
                loading(true);

            let query = {
                limit: self.limit,
                q: search,
                not: JSON.stringify(self.not)
            };

            self.axios.get(`branches/list`, { params: query})
            .then((dataEdificio) => {
                self.items = dataEdificio.data;

                if(self.enableCreate && dataEdificio.data.length == 0) {
                    self.items = [{
                        id: "add",
                        add: true,
                        name: null
                    }];
                }

                if(loading)
                    loading(false);

            }).catch((err) => {
                if(loading)
                    loading(false);

                self.$toastr.e(err, "Exception");
            });
        }, 350),
	},
	beforeMount: function(){
		let self = this;

        if(self.selected) {
            self.buscarSelected(self.selected);
        } else {
            self.onSearch();
        }
	},
    mounted() {
        // this.observer = new IntersectionObserver(this.infiniteScroll)
    }
}
</script>
<style scoped>
    .loader {
        text-align: center;
        color: #bbbbbb;
    }
</style>