<template>
    <form novalidate=true @submit.prevent="enviarFormularioFromForm">
        <div class="row mb-3" v-if="!isModal">
            <div class="col-6">
                <h2 class="text-dark">
                    {{ titulo }}
                </h2>
            </div>
		</div>
        <div class="row">
            <div class="row col-md-12">
                <div v-if="!estaEditando" class="col-md-4 ">
                    <div class="form-group">
                        <label for="code" class="form_label ">Código</label>
                        <input class="form-control" :class="{'is-invalid': validaciones.code.error}" @change="buscarChange()" type="text" v-model="form.code" id="code" />
                    </div>
                </div>
                <div v-if="estaEditando" class="col-md-12">
                    <div class="form-group">
                        <label for="code" class="form_label ">Códigos</label>
                        <v-select multiple v-model="form.codes" :options="codesOptions" id="codes" taggable push-tags/>

                        <!-- <input class="form-control" :class="{'is-invalid': validaciones.code.error}" @change="buscarChange()" type="text" v-model="form.code" id="code" /> -->
                    </div>
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Es multi código</label>
                        <div role="group" class="form-check">
                            <input type="checkbox" class="form-check-input" v-model="isMulticode" />
                        </div>
                    </div>
                </div>
                <div class="col-md-4 " v-if="isMulticode">
                    <ProductosSelect 
                        @onSelected="selectedProduct"
                        :isDisabled="!readonly"
                        />
                </div>
                <div class="col-md-4 ">
                    <div class="form-group">
                        <label for="name" class="form_label ">Nombre</label>
                        <input class="form-control" type="text" v-model="form.name" :readonly="readonly" :class="{'is-invalid': validaciones.name.error}" required="required" maxlength="255" id="name">
                    </div>
                </div>
                <div class="col-md-4 ">
                    <MarcasSelect 
                        :enableCreate="true" 
                        :isInvalid="validaciones.branch_id.error" 
                        :selected="selectedBranchId" 
                        @onSelected="selectedBranch"
                        :isDisabled="readonly"
                        />
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="description" class="form_label ">Descripción</label>
                        <textarea class="form-control" rows="3" type="text" v-model="form.description" :readonly="readonly" maxlength="255" id="description">
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3" v-if="!isModal">
            <div class="col-12">
                <hr />
            </div>
            <div class="col-6 text-left">
                <button type="button" class="btn btn-outline-dark" title="Volver" @click="volver">
                    <font-awesome-icon icon="arrow-left" /> Volver
                </button>
            </div>
            <div class="col-6 text-right">
                <button type="submit" class="btn btn-success" title="Guardar" v-loading-btn="{loading: loading}" >
                    <font-awesome-icon icon="save" /> Guardar
                </button>
            </div>
        </div>
        <JumboScrapper :buscar="textoBuscarJumbo" @onItemSelect="jumboSelected"  />
    </form>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Validaciones } from '../../utils/funciones';
import _ from "lodash";

library.add([faArrowLeft, faSave, faPlus]);

import MarcasSelect from '../marcas/MarcasSelect.vue';
import ProductosSelect from './ProductosSelect.vue';
import JumboScrapper from '../scrappers/JumboScrapper.vue';

export default {
    name: 'ProductosForm',
    components: {
        MarcasSelect,
        ProductosSelect,
        JumboScrapper
    },
    props: {
        save: {
            type: Boolean,
            default: false
        },
        isModal: {
            type: Boolean,
            default: false
        },
        idToEdit: {
            type: String,
            default: null
        },
        codigo: {
            type: String,
            default: null
        },
        triggerBuscarJumbo: {
            type: String,
            default: null
        }
    },
    watch: {
        save: function (value) {
            let self = this;
            if(!self.savingFromForm && value) {
                this.enviarFormulario();
            }
        },
        idToEdit: {
            handler: function(id) {
                let self = this;
                if(id) {
                    console.log('buscar!');
                    self.estaEditando = true;
                    self.buscar({id});
                }
            },
            // deep: true,
            immediate: true
        },
        codigo: {
            handler: function(codigo) {
                let self = this;
                if(codigo) {
                    console.log('codigo productos-form', codigo);
                    self.form.code = codigo;
                    self.buscar({
                        code: self.form.code
                    });
                }
            },
            immediate: true
        },
        isMulticode: function(value) {
            let self = this;

            if(value) {
                self.readonly = true;
            } else {
                self.readonly = false;
                self.form.id = null;
                self.form.branch_id = null;
                self.form.name = null;
                self.form.description = null;

                self.selectedBranchId = null;
            }
        },
        triggerBuscarJumbo: function(value) {
           console.log("buscar! => " + value);
           this.textoBuscarJumbo = value;
        }
    },
    data: () => {
        return {
            textoBuscarJumbo: null,
            titulo: 'Crear producto',
            loading: false,
            estaEditando: false,
            savingFromForm: false,
            isMulticode: false,
            form: {
                id: null,
                product_type_id: null,
                branch_id: null,
                code: null,
                codes: null,
                name: null,
                description: null,
            },
            readonly: true,
            codesOptions: [],
            validaciones: {
                product_type_id: {
                    error: false,
                    reglas: 'not-empty'
                },
                code: {
                    error: false,
                    reglas: 'not-empty'
                },
                // codes: {
                //     error: false,
                //     reglas: 'not-empty'
                // },
                name: {
                    error: false,
                    reglas: 'not-empty'
                },
                branch_id: {
                    error: false,
                    reglas: 'not-empty'
                }
            },
            selectedBranchId: null
        }
    },
    methods: {
        selectedBranch: function(branchId) {
            this.form.branch_id = branchId;
        },
        jumboSelected: function(item) {
            const self = this;
            console.log('jumboSelected item', item);
            self.form.name = item.name;
            self.form.description = item.name;
            self.selectedBranchId = item.brand_id;
            self.form.branch_id = item.brand_id;
        },
        selectedProduct: function(productId) {
            const self = this;

            self.buscarCallback({ id: productId}, function(response) {
                console.log('response', response);

                self.form.id = productId;
                self.form.name = response.data.name;
                self.form.description = response.data.description;
                self.form.branch_id = response.data.branch_id;
                self.form.product_type_id = response.data.product_type_id;

                self.selectedBranchId = response.data.branch_id;
            });
        },
        buscarChange() {
            let self = this;

            self.buscar({
                code: self.form.code
            });
        },
        buscarCallback(params, callback) {
            let self = this;

            self.axios.get(`products/find`, {params})
            .then(callback).catch(function (error) {
                self.$toastr.e(error, "Exception");
            });
        },
        buscar(params) {
            let self = this;

            self.buscarCallback(params, function (response) {
                if(!response.error) {
                    if(_.isEmpty(response.data)) {
                        self.readonly = false;

                        self.form.id = null;
                        // self.form.product_type_id = null;
                        self.form.branch_id = null;
                        self.form.name = null;
                        self.form.codes = [];
                        self.codesOptions = [];
                        self.form.description = null;

                        self.selectedBranchId = null;
                    } else {
                        self.readonly = true;
                        self.form.id = response.data.id;
                        self.form.name = response.data.name;
                        self.form.description = response.data.description;
                        self.form.branch_id = response.data.branch_id;
                        self.form.product_type_id = response.data.product_type_id;

                        self.selectedBranchId = response.data.branch_id;

                        if(!self.estaEditando) {
                            self.$toastr.w(`El producto código <b>${self.form.code}</b> existe en el sistema`, "Advertencia");
                        } else {
                            self.readonly = false;
                            self.form.code = response.data.code;
                            self.form.codes = response.data.codes;
                            self.codesOptions = response.data.codes;
                        }
                    }
                } else {
                    self.$toastr.e(response.message, "Error");
                }
            });
        },
        enviarFormularioFromForm() {
            this.savingFromForm = true;
            this.enviarFormulario();
        },
        enviarFormulario() {
            let self = this;

            console.log('self.form', self.form);

            self.loading = true;
            const validacionesFinal = {...self.validaciones};

            if(self.estaEditando) {
                delete validacionesFinal.code;
            }

            Validaciones.reset(validacionesFinal);
            Validaciones.validar(self.form, validacionesFinal);
            let tieneError = Validaciones.hasError(validacionesFinal);

            if(!tieneError) {
                self.$emit('update:save', true);
                const data = {...self.form };

                let $promise = null;
                delete data.id;
                if(self.estaEditando || self.form.id) {

                    if(data.code) {
                        //para agregar el codigo en el array
                        data.addCode = data.code;
                        delete data.code;
                        delete data.codes;
                    }

                    $promise = self.axios.put(`products/${self.form.id}`, data);
                } else {
                    $promise =  self.axios.post(`products`, data);
                }
                
                $promise
                .then(function(response) {
                    self.$emit('update:save', false);
                    if(response.error) {
                        self.loading = false;
                        self.$toastr.e(response.message, "Error al guardar producto");
                    } else {
                        self.loading = false;
                        self.$toastr.s("Producto guardado exitosamente", "Correcto");
                        self.$emit('entity', response.data);

                        if(!self.isModal) {
                            self.volver();
                        }
                    }
                })
                .catch(function (error) {
                    self.loading = false;
                    self.$emit('update:save', false);
                    self.$toastr.e(error, "Exception");
                });
            } else {
                self.loading = false;
                self.$toastr.e("El formulario tiene errores, verifique", "Error");
                self.$emit('update:save', false);
            }
        }
    },
    beforeMount: function(){
		let self = this;

        let apis = [
            self.axios.get(`product-types/find`, { params: {slug: 'individual'} })
        ];

        let callback = (dataProductType) => {
            self.form.product_type_id = dataProductType.data.id; 
        }

        if(self.$route.params.id) {
            self.estaEditando = true;
            self.titulo = 'Editar producto';

            apis.push(
                self.axios.get(`products/${self.$route.params.id}`)
            );

            callback = (dataProductType, dataProduct) => {
                self.form.product_type_id = dataProductType.id; 

                self.form.id = dataProduct.data.id;
                self.form.product_type_id = dataProduct.data.product_type_id;
                self.form.branch_id = dataProduct.data.branch_id;
                // self.form.code = dataProduct.data.code;
                self.form.codes = dataProduct.data.codes;
                self.codesOptions = dataProduct.data.codes;
                self.form.name = dataProduct.data.name;
                self.form.description = dataProduct.data.description;

                self.readonly = false;
            }
        }

        self.axios.all(apis)
        .then(self.axios.spread(callback))
        .catch((err) => {
            self.$toastr.e(err, "Exception");
        });
    }
}
</script>

<style>
</style>